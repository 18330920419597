export enum CampaignType {
  staticXPlusY = 'staticXPlusY',
  dynamicXPlusY = 'dynamicXPlusY',
  staticPercentageDiscount = 'staticPercentageDiscount',
  dynamicPercentageDiscount = 'dynamicPercentageDiscount',
  fixedPercentageDiscount = 'fixedPercentageDiscount',
  staticAbsoluteDiscount = 'staticAbsoluteDiscount',
  dynamicAbsoluteDiscount = 'dynamicAbsoluteDiscount',
  fixedAbsoluteDiscount = 'fixedAbsoluteDiscount',
  fixedBaseFixedFree = 'fixedBaseFixedFree',
  variableBaseFixedFree = 'variableBaseFixedFree',
  fixedBaseVariableFree = 'fixedBaseVariableFree',
  peCampaign = 'campaignDays',
}
